<template>
  <div>
    <table border=0 cellpadding=0 cellspacing=0 width=1192 style='border-collapse:
 collapse;table-layout:fixed;width:894pt'>
      <col width=45 style='mso-width-source:userset;mso-width-alt:1450;width:34pt'>
      <col width=76 style='mso-width-source:userset;mso-width-alt:2432;width:57pt'>
      <col class=xl65 width=91 style='mso-width-source:userset;mso-width-alt:2901;
 width:68pt'>
      <col class=xl66 width=125 style='mso-width-source:userset;mso-width-alt:4010;
 width:94pt'>
      <col class=xl67 width=119 style='mso-width-source:userset;mso-width-alt:3797;
 width:89pt'>
      <col class=xl66 width=120 style='mso-width-source:userset;mso-width-alt:3840;
 width:90pt'>
      <col class=xl67 width=117 style='mso-width-source:userset;mso-width-alt:3754;
 width:88pt'>
      <col width=207 style='mso-width-source:userset;mso-width-alt:6613;width:155pt'>
      <col width=220 style='mso-width-source:userset;mso-width-alt:7040;width:165pt'>
      <col width=72 style='width:54pt'>
      <tr height=56 style='mso-height-source:userset;height:42.0pt'>
        <td colspan=10 height=56 class=xl83 width=1192 style='height:42.0pt;
  width:894pt'>《中有救度诵念时间简表》
        </td>
      </tr>
      <tr height=48 style='mso-height-source:userset;height:36.0pt'>
        <td height=48 class=xl68 style='height:36.0pt'>姓名</td>
        <td class=xl69 style='border-left:none'>{{ timeStr.name }}</td>
        <td class=xl68 style='border-left:none'>年龄</td>
        <td class=xl68 style='border-left:none'>{{ timeStr.age }}</td>
        <td class=xl68 style='border-left:none'>籍贯</td>
        <td class=xl68 style='border-left:none'>{{ timeStr.location }}</td>
        <td class=xl68 style='border-left:none'>舍报时间</td>
        <td class=xl70 style='border-left:none'>{{ dateArr[0] }}</td>
        <td colspan=2 class=xl84 style='border-left:none'>{{ timeArr[0] }}</td>
      </tr>
      <tr height=27 style='mso-height-source:userset;height:20.0pt'>
        <td rowspan=2 height=51 class=xl93 width=45 style='border-bottom:1.0pt solid black;
  height:38.0pt;width:34pt'>中有
        </td>
        <td colspan=2 class=xl85 style='border-right:1.0pt solid black;border-left:
  none'>每日念诵
        </td>
        <td colspan=5 class=xl87 style='border-right:1.0pt solid black;border-left:
  none'>起始念诵
        </td>
        <td colspan=2 class=xl90 style='border-right:1.0pt solid black;border-left:
  none'>念诵中有祈祷文
        </td>
      </tr>
      <tr height=24 style='mso-height-source:userset;height:18.0pt'>
        <td colspan=2 height=24 class=xl72 width=167 style='height:18.0pt;border-left:
  none;width:125pt'>阶段
        </td>
        <td class=xl73 width=125 style='border-left:none;width:94pt'>起始日期</td>
        <td class=xl74 width=119 style='border-left:none;width:89pt'>起始时间</td>
        <td class=xl73 width=120 style='border-left:none;width:90pt'>终止日期</td>
        <td class=xl74 width=117 style='border-left:none;width:88pt'>终止时间</td>
        <td class=xl72 width=207 style='border-left:none;width:155pt'>时间</td>
        <td colspan=2 class=xl72 width=292 style='border-left:none;width:219pt'>念诵内容</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td rowspan=11 height=300 class=xl94 width=45 style='border-bottom:1.0pt solid black;
  height:225.25pt;width:34pt'>死亡中有
        </td>
        <td rowspan=7 class=xl94 width=76 style='border-bottom:1.0pt solid black;
  width:57pt'>第一阶段
        </td>
        <td rowspan=5 class=xl75 width=91 style='border-bottom:1.0pt solid black;
  width:68pt'>呼吸停止前后
        </td>
        <td rowspan=5 class=xl76 width=125 style='border-bottom:1.0pt solid black;
  width:94pt'>{{ dateArr[0] }}
        </td>
        <td rowspan=5 class=xl97 width=119 style='border-bottom:1.0pt solid black;
  width:89pt'>{{ timeArr[0] }}
        </td>
        <td colspan=2 rowspan=5 class=xl105 width=237 style='border-bottom:1.0pt solid black;
  width:178pt'>设{{ dateArr[0] + timeArr[0] }}呼吸停止舍报。呼吸停止以前念诵右文四种导示。
        </td>
        <td rowspan=2 class=xl93 width=207 style='border-bottom:1.0pt solid black;
  border-top:none;width:155pt'>外气将断未绝以前
        </td>
        <td class=xl81 width=220 style='border-top:none;border-left:none;border-bottom:none;width:165pt'>（1）指示明光寻求大道文<span
            style='mso-spacerun:yes'></span></td>
        <td class=xl81 width=72 style='border-top:none;border-left:none;width:54pt'>13页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl82 width=220 style='height:15.0pt;border-left:none;
  width:165pt'>（2）四大融入指示文
        </td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>14页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td rowspan=3 height=60 class=xl71 width=207 style='height:45.0pt;width:155pt'>外气将断时</td>
        <td class=xl82 width=220 style='border-left:none;border-bottom:none;width:165pt'>（2）四大融入指示文<span
            style='mso-spacerun:yes'></span></td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>14 页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl82 width=220 style='height:15.0pt;border-left:none;border-bottom:none;width:165pt'>（3）发心证认死位明光文<span style='mso-spacerun:yes'></span></td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>15 页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl82 width=220 style='height:15.0pt;border-left:none;
  width:165pt'>（4）明光直指教授<span style='mso-spacerun:yes'></span></td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>15 页</td>
      </tr>
      <tr height=29 style='mso-height-source:userset;height:22.0pt'>
        <td rowspan=2 height=68 class=xl75 width=91 style='border-bottom:1.0pt solid black;
  height:51.0pt;width:68pt'>呼吸停止后二十分钟<span style='mso-spacerun:yes'></span></td>
        <td rowspan=2 class=xl76 width=125 style='border-bottom:1.0pt solid black;
  width:94pt'>{{ dateArr[0] }}
        </td>
        <td rowspan=2 class=xl77 width=119 style='border-bottom:1.0pt solid black;
  width:89pt'>{{ timeArr[0] }}
        </td>
        <td rowspan=2 class=xl76 width=120 style='border-bottom:1.0pt solid black;
  width:90pt'>{{ dateArr[1] }}
        </td>
        <td rowspan=2 class=xl77 width=117 style='border-bottom:1.0pt solid black;
  width:88pt'>{{ timeArr[1] }}
        </td>
        <td rowspan=2 class=xl71 width=207 style='width:155pt'>呼吸停止顿饭许需反复念诵前三、四导示。气断二十分钟后进入三天半闷绝期。</td>
        <td class=xl82 width=220 style='border-left:none;border-bottom:none;width:165pt'>（3）发心证认死位明光文<span
            style='mso-spacerun:yes'></span></td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>16 页</td>
      </tr>
      <tr height=39 style='mso-height-source:userset;height:29.0pt'>
        <td height=39 class=xl82 width=220 style='height:29.0pt;border-left:none;
  width:165pt'>（4）明光直指教授
        </td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>17页</td>
      </tr>
      <tr height=32 style='mso-height-source:userset;height:24.0pt'>
        <td rowspan=4 height=132 class=xl94 width=76 style='border-bottom:1.0pt solid black;
  height:99.25pt;width:57pt'>第二阶段
        </td>
        <td rowspan=4 class=xl75 width=91 style='border-bottom:1.0pt solid black;
  width:68pt'>闷绝期
        </td>
        <td class=xl116 width=125 style='border-top:none;border-left:none;width:94pt'>{{ dateArr[1] }}</td>
        <td class=xl117 width=119 style='border-top:none;border-left:none;width:89pt'>{{ timeArr[1] }}</td>
        <td class=xl116 width=120 style='border-top:none;border-left:none;width:90pt'>{{ dateArr[2] }}</td>
        <td class=xl117 width=117 style='border-top:none;border-left:none;width:88pt'>{{ timeArr[2] }}</td>
        <td class=xl118 width=207 style='border-top:none;border-left:none;width:155pt'>第一天</td>
        <td class=xl119 width=220 style='border-top:none;border-left:none;border-bottom:none;width:165pt'>（5）本尊道场观<span
            style='mso-spacerun:yes'></span></td>
        <td class=xl119 width=72 style='border-top:none;border-left:none;width:54pt'>17
          页
        </td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl113 width=125 style='height:15.0pt;border-left:none;
  width:94pt'>{{ dateArr[3] }}
        </td>
        <td class=xl114 width=119 style='border-left:none;width:89pt'>{{ timeArr[3] }}</td>
        <td class=xl113 width=120 style='border-left:none;width:90pt'>{{ dateArr[4] }}</td>
        <td class=xl114 width=117 style='border-left:none;width:88pt'>{{ timeArr[4] }}</td>
        <td class=xl115 width=207 style='border-left:none;width:155pt'>第二天</td>
        <td rowspan=2 class=xl100 width=220 style='border-bottom:1.0pt solid black;
  width:165pt'>（6）法性中有指授及六句要偈
        </td>
        <td rowspan=2 class=xl100 width=72 style='border-bottom:1.0pt solid black;
  width:54pt'>18页
        </td>
      </tr>
      <tr height=25 style='mso-height-source:userset;height:19.0pt'>
        <td height=25 class=xl76 width=125 style='height:19.0pt;border-left:none;
  width:94pt'>{{ dateArr[5] }}
        </td>
        <td class=xl77 width=119 style='border-left:none;width:89pt'>{{ timeArr[5] }}</td>
        <td class=xl76 width=120 style='border-left:none;width:90pt'>{{ dateArr[6] }}</td>
        <td class=xl77 width=117 style='border-left:none;width:88pt'>{{ timeArr[6] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>三天有半</td>
      </tr>
      <tr height=55 style='mso-height-source:userset;height:41.25pt'>
        <td height=55 class=xl73 width=125 style='height:41.25pt;border-left:none;
  width:94pt'>{{ dateArr[7] }}
        </td>
        <td class=xl74 width=119 style='border-left:none;width:89pt'>{{ timeArr[7] }}</td>
        <td class=xl73 width=120 style='border-left:none;width:90pt'></td>
        <td class=xl74 width=117 style='border-left:none;width:88pt'></td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>闷绝初醒</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>（7）令其认识本来面目教授</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>19页</td>
      </tr>
      <tr height=28 style='mso-height-source:userset;height:21.0pt'>
        <td rowspan=14 height=311 class=xl71 width=45 style='height:234.75pt;
  width:34pt'>法性中有
        </td>
        <td rowspan=14 class=xl71 width=76 style='width:57pt'>第三阶段</td>
        <td class=xl75 width=91 style='border-left:none;width:68pt'>初周</td>
        <td class=xl116 width=125 style='border-top:none;border-left:none;width:94pt'>{{ dateArr[8] }}</td>
        <td class=xl117 width=119 style='border-top:none;border-left:none;width:89pt'>{{ timeArr[8] }}</td>
        <td class=xl116 width=120 style='border-top:none;border-left:none;width:90pt'>{{ dateArr[9] }}</td>
        <td class=xl117 width=117 style='border-top:none;border-left:none;width:88pt'>{{ timeArr[9] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第一日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>毗卢遮那如来<span
            style='mso-spacerun:yes'></span></td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>20 页</td>
      </tr>
      <tr height=27 style='mso-height-source:userset;height:20.0pt'>
        <td rowspan=5 height=111 class=xl75 width=91 style='height:83.75pt;
  width:68pt'>寂静本尊
        </td>
        <td class=xl120 width=125 style='border-top:none;border-left:none;width:94pt'>{{ dateArr[10] }}</td>
        <td class=xl121 width=119 style='border-top:none;border-left:none;width:89pt'>{{ timeArr[10] }}</td>
        <td class=xl120 width=120 style='border-top:none;border-left:none;width:90pt'>{{ dateArr[11] }}</td>
        <td class=xl121 width=117 style='border-top:none;border-left:none;width:88pt'>{{ timeArr[11] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第二日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>不动如来</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>22页</td>
      </tr>
      <tr height=20 style='mso-height-source:userset;height:15.75pt'>
        <td height=20 class=xl120 width=125 style='height:15.75pt;border-top:none;
  border-left:none;width:94pt'>{{ dateArr[12] }}
        </td>
        <td class=xl121 width=119 style='border-top:none;border-left:none;width:89pt'>{{ timeArr[12] }}</td>
        <td class=xl120 width=120 style='border-top:none;border-left:none;width:90pt'>{{ dateArr[13] }}</td>
        <td class=xl121 width=117 style='border-top:none;border-left:none;width:88pt'>{{ timeArr[13] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第三日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>宝生如来</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>24页</td>
      </tr>
      <tr height=24 style='mso-height-source:userset;height:18.0pt'>
        <td height=24 class=xl120 width=125 style='height:18.0pt;border-top:none;
  border-left:none;width:94pt'>{{ dateArr[14] }}
        </td>
        <td class=xl121 width=119 style='border-top:none;border-left:none;width:89pt'>{{ timeArr[14] }}</td>
        <td class=xl120 width=120 style='border-top:none;border-left:none;width:90pt'>{{ dateArr[15] }}</td>
        <td class=xl121 width=117 style='border-top:none;border-left:none;width:88pt'>{{ timeArr[15] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第四日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>阿弥陀如来</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>26页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl120 width=125 style='height:15.0pt;border-top:none;
  border-left:none;width:94pt'>{{ dateArr[16] }}
        </td>
        <td class=xl121 width=119 style='border-top:none;border-left:none;width:89pt'>{{ timeArr[16] }}</td>
        <td class=xl120 width=120 style='border-top:none;border-left:none;width:90pt'>{{ dateArr[17] }}</td>
        <td class=xl121 width=117 style='border-top:none;border-left:none;width:88pt'>{{ timeArr[17] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第五日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>不空成就如来</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>28页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl122 style='height:15.0pt;border-top:none;border-left:
  none'>{{ dateArr[18] }}
        </td>
        <td class=xl123 style='border-top:none;border-left:none'>{{ timeArr[18] }}</td>
        <td class=xl122 style='border-top:none;border-left:none'>{{ dateArr[19] }}</td>
        <td class=xl123 style='border-top:none;border-left:none'>{{ timeArr[19] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第六日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>五部如来</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>30页</td>
      </tr>
      <tr height=29 style='mso-height-source:userset;height:22.0pt'>
        <td height=29 class=xl78 style='height:22.0pt;border-left:none'>空行本尊</td>
        <td class=xl79 style='border-left:none'>{{ dateArr[20] }}</td>
        <td class=xl80 style='border-left:none'>{{ timeArr[20] }}</td>
        <td class=xl79 style='border-left:none'>{{ dateArr[21] }}</td>
        <td class=xl80 style='border-left:none'>{{ timeArr[21] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第七日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>持明圣众</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>35页</td>
      </tr>
      <tr height=21 style='height:16.0pt'>
        <td height=21 class=xl75 width=91 style='height:16.0pt;border-left:none;
  width:68pt'>次周
        </td>
        <td class=xl116 width=125 style='border-top:none;border-left:none;width:94pt'>{{ dateArr[22] }}</td>
        <td class=xl117 width=119 style='border-top:none;border-left:none;width:89pt'>{{ timeArr[22] }}</td>
        <td class=xl116 width=120 style='border-top:none;border-left:none;width:90pt'>{{ dateArr[23] }}</td>
        <td class=xl117 width=117 style='border-top:none;border-left:none;width:88pt'>{{ timeArr[23] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第八日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>佛陀兮噜迦</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>43页</td>
      </tr>
      <tr height=21 style='height:16.0pt'>
        <td rowspan=6 height=122 class=xl75 width=91 style='border-bottom:1.0pt solid black;
  height:92.0pt;width:68pt'>忿怒本尊
        </td>
        <td class=xl120 width=125 style='border-top:none;border-left:none;width:94pt'>{{ dateArr[24] }}</td>
        <td class=xl121 width=119 style='border-top:none;border-left:none;width:89pt'>{{ timeArr[24] }}</td>
        <td class=xl120 width=120 style='border-top:none;border-left:none;width:90pt'>{{ dateArr[25] }}</td>
        <td class=xl121 width=117 style='border-top:none;border-left:none;width:88pt'>{{ timeArr[25] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第九日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>金刚兮噜迦</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>44页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl120 width=125 style='height:15.0pt;border-top:none;
  border-left:none;width:94pt'>{{ dateArr[26] }}
        </td>
        <td class=xl121 width=119 style='border-top:none;border-left:none;width:89pt'>{{ timeArr[26] }}</td>
        <td class=xl120 width=120 style='border-top:none;border-left:none;width:90pt'>{{ dateArr[27] }}</td>
        <td class=xl121 width=117 style='border-top:none;border-left:none;width:88pt'>{{ timeArr[27] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第十日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>珍宝兮噜迦</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>45页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl122 style='height:15.0pt;border-top:none;border-left:
  none'>{{ dateArr[28] }}
        </td>
        <td class=xl123 style='border-top:none;border-left:none'>{{ timeArr[28] }}</td>
        <td class=xl122 style='border-top:none;border-left:none'>{{ dateArr[29] }}</td>
        <td class=xl123 style='border-top:none;border-left:none'>{{ timeArr[29] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第十一日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>莲花兮噜迦</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>46页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl122 style='height:15.0pt;border-top:none;border-left:
  none'>{{ dateArr[30] }}
        </td>
        <td class=xl123 style='border-top:none;border-left:none'>{{ timeArr[30] }}</td>
        <td class=xl122 style='border-top:none;border-left:none'>{{ dateArr[31] }}</td>
        <td class=xl123 style='border-top:none;border-left:none'>{{ timeArr[31] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第十二日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>羯摩兮噜迦</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>47页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl122 style='height:15.0pt;border-top:none;border-left:
  none'>{{ dateArr[32] }}
        </td>
        <td class=xl123 style='border-top:none;border-left:none'>{{ timeArr[32] }}</td>
        <td class=xl122 style='border-top:none;border-left:none'>{{ dateArr[33] }}</td>
        <td class=xl123 style='border-top:none;border-left:none'>{{ timeArr[33] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第十三日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>八大忿怒母、非人八大母</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>49页</td>
      </tr>
      <tr height=21 style='height:16.0pt'>
        <td height=21 class=xl79 style='height:16.0pt;border-left:none'>{{ dateArr[34] }}</td>
        <td class=xl80 style='border-left:none'>{{ timeArr[34] }}</td>
        <td class=xl79 style='border-left:none'>{{ dateArr[35] }}</td>
        <td class=xl80 style='border-left:none'>{{ timeArr[35] }}</td>
        <td class=xl71 width=207 style='border-left:none;width:155pt'>第十四日</td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>四门守护母、廿八瑜伽自在母</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>51页</td>
      </tr>
      <tr height=21 style='height:16.0pt'>
        <td rowspan=3 height=103 class=xl71 width=45 style='height:78.0pt;width:34pt'>轮回中有</td>
        <td rowspan=3 class=xl71 width=76 style='width:57pt'>第四阶段</td>
        <td rowspan=3 class=xl75 width=91 style='border-bottom:1.0pt solid black;
  width:68pt'>三十五日
        </td>
        <td class=xl124 width=125 style='border-top:none;border-left:none;width:94pt'>{{ dateArr[36] }}</td>
        <td class=xl125 width=119 style='border-top:none;width:89pt'>{{ timeArr[36] }}</td>
        <td class=xl126 width=120 style='border-top:none;width:90pt'>{{ dateArr[37] }}</td>
        <td class=xl127 width=117 style='border-top:none;width:88pt'>{{ timeArr[37] }}</td>
        <td rowspan=3 class=xl94 width=207 style='border-bottom:1.0pt solid black;
  width:155pt'>第十五日至第四十九日。
        </td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>第一日，导示一至导示十二。</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>58-66页</td>
      </tr>
      <tr height=21 style='height:16.0pt'>
        <td colspan=4 rowspan=2 height=82 class=xl101 width=481 style='border-bottom:
  1.0pt solid black;height:62.0pt;width:361pt'>
          第十五日至第四十九日。加前四日，共计五十三日。引导文每天念诵导示一至导示二十四即全文及咒语最好。亦可分开念诵：第一日导示一至十二为止；第二日导示十三至导示第十九；第三日导示第二十念至卷末；第四日起再从头顺次每三日念一全卷，至七七期满为止。
        </td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>第二日，导示十三至导示十九。</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>69-75页</td>
      </tr>
      <tr height=61 style='mso-height-source:userset;height:46.0pt'>
        <td height=61 class=xl82 width=220 style='height:46.0pt;border-left:none;
  width:165pt'>第三日，导示二十至导示二十四卷末。
        </td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>77-82页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td rowspan=2 height=40 class=xl94 width=45 style='border-bottom:1.0pt solid black;
  height:30.0pt;width:34pt'>结行
        </td>
        <td colspan=2 rowspan=2 class=xl111 width=167 style='border-right:1.0pt solid black;
  border-bottom:1.0pt solid black;width:125pt'>每日念诵
        </td>
        <td colspan=5 rowspan=2 class=xl107 width=688 style='border-right:1.0pt solid black;
  border-bottom:1.0pt solid black;width:516pt'>结行念诵
        </td>
        <td class=xl82 width=220 style='border-left:none;width:165pt'>本尊心咒</td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>83页</td>
      </tr>
      <tr height=20 style='height:15.0pt'>
        <td height=20 class=xl82 width=220 style='height:15.0pt;border-left:none;
  width:165pt'>烧香供养法
        </td>
        <td class=xl82 width=72 style='border-left:none;width:54pt'>87页</td>
      </tr>
      <tr height=0 style='display:none'>
        <td width=45 style='width:34pt'></td>
        <td width=76 style='width:57pt'></td>
        <td width=91 style='width:68pt'></td>
        <td width=125 style='width:94pt'></td>
        <td width=119 style='width:89pt'></td>
        <td width=120 style='width:90pt'></td>
        <td width=117 style='width:88pt'></td>
        <td width=207 style='width:155pt'></td>
        <td width=220 style='width:165pt'></td>
        <td width=72 style='width:54pt'></td>
      </tr>
    </table>
  </div>
</template>

<script>

import TimeUtils from "@/components/TimeUtils";

export default {
  name: 'HelloWorld',
  props: {
    timeStr: Object
  },
  data() {
    return {
      timeArr: [],
      dateArr: []
    }
  },
  watch: {
    timeStr: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        if (newVal !== 0) {
          this.timeArr = []
          this.dateArr = []
          const cusTime = new Date(newVal.time);
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", cusTime))
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", cusTime))
          const firstTime = cusTime.getTime() + 20 * 60 * 1000
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(firstTime)));
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(firstTime)))
          const secondTime = firstTime + 24 * 60 * 60 * 1000
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(secondTime)));
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(secondTime)))
          const thirdTime = secondTime + 24 * 60 * 60 * 1000
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(secondTime)))
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(secondTime)))
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(thirdTime)));
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(thirdTime)))
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(thirdTime)))
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(thirdTime)))
          const fourTime = thirdTime + 36 * 60 * 60 * 1000
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(fourTime)));
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(fourTime)))
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(fourTime)))
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(fourTime)))
          let oldValue = fourTime
          for (let i = 0; i < 14; i++) {
            this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(oldValue)))
            this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(oldValue)))
            const newValue = oldValue + 24 * 60 * 60 * 1000;
            oldValue = newValue
            this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(newValue)))
            this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(newValue)))
          }
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(oldValue)))
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(oldValue)))
          const newValue = oldValue + 35 * 24 * 60 * 60 * 1000;
          this.timeArr.push(TimeUtils.dateFtt("hh时mm分ss秒", new Date(newValue)))
          this.dateArr.push(TimeUtils.dateFtt("yyyy年MM月dd日", new Date(newValue)))
        }
      }
    }
  }
}
</script>

<style scoped>
tr {
  mso-height-source: auto;
  mso-ruby-visibility: none;
}

col {
  mso-width-source: auto;
  mso-ruby-visibility: none;
}

br {
  mso-data-placement: same-cell;
}

ruby {
  ruby-align: left;
}

.style0 {
  mso-number-format: General;
  text-align: general;
  vertical-align: middle;
  white-space: nowrap;
  mso-rotate: 0;
  mso-background-source: auto;
  mso-pattern: auto;
  color: black;
  font-size: 11.0pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: 宋体;
  mso-generic-font-family: auto;
  mso-font-charset: 134;
  border: none;
  mso-protection: locked visible;
  mso-style-name: 常规;
  mso-style-id: 0;
}

td {
  mso-style-parent: style0;
  padding-top: 1px;
  padding-right: 1px;
  padding-left: 1px;
  mso-ignore: padding;
  color: black;
  font-size: 11.0pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: 宋体;
  mso-generic-font-family: auto;
  mso-font-charset: 134;
  mso-number-format: General;
  text-align: general;
  vertical-align: middle;
  border: none;
  mso-background-source: auto;
  mso-pattern: auto;
  mso-protection: locked visible;
  white-space: nowrap;
  mso-rotate: 0;
}

.xl65 {
  mso-style-parent: style0;
  mso-number-format: "\@";
}

.xl66 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
}

.xl67 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
}

.xl68 {
  mso-style-parent: style0;
  font-size: 12.0pt;
  mso-number-format: "\[ENG\]\[$-F800\]dddd\\\,\\ mmmm\\ dd\\\,\\ yyyy";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
}

.xl69 {
  mso-style-parent: style0;
  font-size: 12.0pt;
  mso-number-format: "\[ENG\]\[$-F800\]dddd\\\,\\ mmmm\\ dd\\\,\\ yyyy";
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
}

.xl70 {
  mso-style-parent: style0;
  font-size: 20.0pt;
  mso-number-format: "yyyy\/m\/d\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
}

.xl71 {
  mso-style-parent: style0;
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl72 {
  mso-style-parent: style0;
  text-align: center;
  border: 1.0pt solid windowtext;
  white-space: normal;
}

.xl73 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border: 1.0pt solid windowtext;
  white-space: normal;
}

.xl74 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border: 1.0pt solid windowtext;
  white-space: normal;
}

.xl75 {
  mso-style-parent: style0;
  mso-number-format: "\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl76 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl77 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl78 {
  mso-style-parent: style0;
  mso-number-format: "\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
}

.xl79 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
}

.xl80 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
}

.xl81 {
  mso-style-parent: style0;
  border: 1.0pt solid windowtext;
  white-space: normal;
}

.xl82 {
  mso-style-parent: style0;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl83 {
  mso-style-parent: style0;
  font-size: 24.0pt;
  mso-number-format: "\[ENG\]\[$-F800\]dddd\\\,\\ mmmm\\ dd\\\,\\ yyyy";
  text-align: center;
}

.xl84 {
  mso-style-parent: style0;
  font-size: 20.0pt;
  mso-number-format: "h\:mm\:ss\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
}

.xl85 {
  mso-style-parent: style0;
  mso-number-format: "\[ENG\]\[$-F800\]dddd\\\,\\ mmmm\\ dd\\\,\\ yyyy";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
}

.xl86 {
  mso-style-parent: style0;
  mso-number-format: "\[ENG\]\[$-F800\]dddd\\\,\\ mmmm\\ dd\\\,\\ yyyy";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: none;
}

.xl87 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
}

.xl88 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.xl89 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: none;
}

.xl90 {
  mso-style-parent: style0;
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
}

.xl91 {
  mso-style-parent: style0;
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: none;
}

.xl92 {
  mso-style-parent: style0;
  mso-number-format: "\@";
  text-align: center;
  border: 1.0pt solid windowtext;
  white-space: normal;
}

.xl93 {
  mso-style-parent: style0;
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl94 {
  mso-style-parent: style0;
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl95 {
  mso-style-parent: style0;
  mso-number-format: "\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl96 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl97 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: none;
  white-space: normal;
}

.xl98 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: none;
  white-space: normal;
}

.xl99 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl100 {
  mso-style-parent: style0;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl101 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: left;
  border-top: none;
  border-right: none;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl102 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: left;
  white-space: normal;
}

.xl103 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: left;
  border-top: none;
  border-right: none;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl104 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: left;
  border-top: none;
  border-right: none;
  border-bottom: 1.0pt solid windowtext;
  border-left: none;
  white-space: normal;
}

.xl105 {
  mso-style-parent: style0;
  white-space: normal;
}

.xl106 {
  mso-style-parent: style0;
  border-top: none;
  border-right: none;
  border-bottom: 1.0pt solid windowtext;
  border-left: none;
  white-space: normal;
}

.xl107 {
  mso-style-parent: style0;
  text-align: center;
  white-space: normal;
}

.xl108 {
  mso-style-parent: style0;
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: none;
  border-left: none;
  white-space: normal;
}

.xl109 {
  mso-style-parent: style0;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: 1.0pt solid windowtext;
  border-left: none;
  white-space: normal;
}

.xl110 {
  mso-style-parent: style0;
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: 1.0pt solid windowtext;
  border-left: none;
  white-space: normal;
}

.xl111 {
  mso-style-parent: style0;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl112 {
  mso-style-parent: style0;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: 1.0pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl113 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl114 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl115 {
  mso-style-parent: style0;
  text-align: center;
  border-top: none;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl116 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl117 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl118 {
  mso-style-parent: style0;
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl119 {
  mso-style-parent: style0;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl120 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: .5pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl121 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: .5pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl122 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: .5pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
}

.xl123 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: .5pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
}

.xl124 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: none;
  border-bottom: .5pt solid windowtext;
  border-left: 1.0pt solid windowtext;
  white-space: normal;
}

.xl125 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: none;
  border-bottom: .5pt solid windowtext;
  border-left: .5pt solid windowtext;
  white-space: normal;
}

.xl126 {
  mso-style-parent: style0;
  mso-number-format: "yyyy\0022年\0022m\0022月\0022d\0022日\0022\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: none;
  border-bottom: .5pt solid windowtext;
  border-left: .5pt solid windowtext;
  white-space: normal;
}

.xl127 {
  mso-style-parent: style0;
  mso-number-format: "h\0022时\0022mm\0022分\0022ss\0022秒\0022\;\@";
  text-align: center;
  border-top: 1.0pt solid windowtext;
  border-right: 1.0pt solid windowtext;
  border-bottom: .5pt solid windowtext;
  border-left: .5pt solid windowtext;
  white-space: normal;
}

table {
  mso-displayed-decimal-separator: "\.";
  mso-displayed-thousand-separator: "\,";
}

@page {
  margin: .75in .7in .75in .7in;
  mso-header-margin: .3in;
  mso-footer-margin: .3in;
}

ruby {
  ruby-align: left;
}

rt {
  color: windowtext;
  font-size: 9.0pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: 宋体;
  mso-generic-font-family: auto;
  mso-font-charset: 134;
  mso-char-type: none;
  display: none;
}
</style>
