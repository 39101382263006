<template>
  <div>
    <div>
      <h1>中有救度时间计算</h1>
    </div>
    <div><p>姓名，年龄，籍贯可以不填，舍报时间为必填项</p></div>
    <el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="numberValidateForm.name"></el-input>
      </el-form-item>
      <el-form-item label="年龄" prop="age">
        <el-input v-model="numberValidateForm.age"></el-input>
      </el-form-item>
      <el-form-item label="籍贯" prop="location">
        <el-input v-model="numberValidateForm.location"></el-input>
      </el-form-item>
      <el-form-item
          label="舍报时间"
          prop="time"
          :editable="false"
          value-format="timestamp"
          :rules="[{ required: true, message: '舍报时间不能为空'}]"
      >
        <el-date-picker
            v-model="numberValidateForm.time"
            type="datetime"
            align="right"
            placeholder="选择日期时间" width="100">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('numberValidateForm')">提交</el-button>
        <el-button @click="resetForm('numberValidateForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'Form',
  data() {
    return {
      numberValidateForm: {
        name: '',
        time: '',
        age: '',
        location: ''
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('changeTime', this.numberValidateForm);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit('reset');
    }
  }
}
</script>

<style scoped>
.el-date-editor.el-input {
  width: 90%
}
</style>
