<template>
  <div>
    <Form @changeTime="changeTime" v-show="formVisible" @reset="reset"/>
    <el-button plain @click="hiddenForm">{{buttonText}}表单</el-button>
    <HelloWorld v-show="timeVar.close!==1" :time-str="timeVar"/>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import Form from "@/components/Form";

export default {
  name: 'Total',
  components: {
    HelloWorld,
    Form
  },
  data() {
    return {
      formVisible: true,
      timeVar: {close:1},
      buttonText: '隐藏'
    }
  },
  methods: {
    changeTime(val) {
      this.timeVar = val
    },
    reset() {
      this.timeVar={close:1}
    },
    hiddenForm(){
      if (this.formVisible) {
        this.formVisible = false;
        this.buttonText = '显示'
      }else{
        this.formVisible = true;
        this.buttonText = '隐藏'
      }
    }
  }
}
</script>

<style>
</style>
